.gamesWrapper {
  margin-left: auto;
  background: white;
  border-radius: 12px;
  justify-content: space-around;
  flex-direction: column;
  padding: 2vh 2vw;

  &__dropdown {
    margin: 0 auto;
    padding-bottom: 1vh;
  }

  &__content {
    margin-right: auto;
    flex-direction: column;

    img {
      border-radius: 8px;
      width: 48px;
      height: 48px;
    }

    &__game {
      margin: 1vh 0;

      &__info {
        padding: 0 1vw;
        flex-direction: column;
        justify-content: center;

      }
    }

  }

  &__more {
    padding-top: 1vh;
    align-self: flex-end;
    cursor: pointer;
    color: $infoBlue;
  }

}