.deviceItem {
  display:inline-block;
  width: 100%;
  @include itemBaseStyles;

  &__name {
    padding-bottom: 2vh;
  }

  &__info {

    &__playlist {
      span {
        font-weight: 600;
      }
    }

    &__progress {
      width: 35%;

      &__hours {
        white-space: nowrap
      }
    }
  }
}