$white: #FFFFFF;
$blue0: #1D83AF;
$blue1: #37A0CE;
$blue2: #CEE8F2;
$blue3: #EBF5F9;
$black: #111213;
$gray1: #4B5058;
$gray2: #676E79;
$gray3: #808893;
$gray4: #DEE0E3;
$gray5: #F4F5F5;
$red: #DE3546;
$green: #1FC180;
$yellow: #E0B43E;
$infoPurple: #8779D1;
$infoRed: #ED6C7B;
$infoYellow: #F5BD56;
$infoGreen: #6EE3AB;
$infoBlue: #4CA0F5;