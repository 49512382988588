.device {
  @include itemWrapperStyles;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  :last-child{
    border: 0;
  }

  margin-bottom: 3vh;
}

.playlistsWrapper {
  display: flex;
  flex-direction: column;
  gap: 3vh;
}