.comparePlaylists {
  width: inherit;
  overflow: hidden;
  flex-direction: column;

  &__searchWrapper {
    .searchBar {
      padding: 0 2vw 2vh;
      height: 7vh;
    }

    p {
      padding: 2vh 2vw 0;
      font-weight: 600;

      span {
        color: $blue1;
      }
    }
  }

  &__secondBlock {

    height: 75%;
    overflow-y: overlay;
    overflow-x: hidden;

    .accordionDevice {
      h3 {
        padding: 1vh 1vw;
      }

      p {
        padding: 0 1vw 1vh;
      }
    }

    //Compare playlist Item

    &__item {
      @include itemWrapperStyles;
      padding: 2vh 2vw;

      div:first-child {
        margin-bottom: 0.5vh;
      }

      .width14 {
        width: 14%;
      }
    }

    .playlistSelectedNOT {
      &:hover {
        color: $black;
        background: $gray5;
      }
    }

    .playlistSelected {
      color: $black;
      background: $blue3;

      &:hover {
        background: $blue2;
      }
    }

    //Compare playlist Item END


  }

  &__footer {
    border-top: solid 1px $gray4;
    height: 10vh;
    align-items: center;
    justify-content: flex-end;
  }

  &__modal {
    background: $blue3;
    overflow: auto;

    .compare:last-child{
      margin-right: 2vw;
    }
  }
}