.searchBar {
  height: 9vh;
  padding: 2vh 0 2vh 2vw;
  background: white;
  align-items: flex-end;
  border-bottom: 1px solid $gray4;
  margin: 0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;

  &__textField {
    width: 100%;
    color: $blue1;
  }

  &__dropdownFilter {
    width: 20vw;
    color: $blue1;
  }
}