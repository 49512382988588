@mixin itemWrapperStyles {
  background: $white;
  border-radius: 12px;
}

@mixin itemBaseStyles {
  overflow: hidden;
  padding: 3vh 2vw;
  background: $white;
  height: 13vh;
  border-bottom: 1px solid $gray4;

  &:hover{
    background: $gray5;
  }
}