//default playlist
.playlist {
  &__donut {
    @include itemWrapperStyles;
    overflow: hidden;
    margin-bottom: 3vh;

    //donut position
    :first-child {
      align-self: center;
    }

    //used for border of Score element
    .score:nth-child(2) {
      border-right: 1px solid $blue3;
    }
  }

  &__heatmap {
    @include itemWrapperStyles;
    overflow: hidden;
    background: white;
    padding: 2vh 2vw;
    margin-bottom: 3vh;
    display: flex;
      flex-direction: column;
      gap: 3vh;
  }
}

//playlist in PlaylistCompare mode
  .playlistCompare {
    overflow-y: auto;
  }

