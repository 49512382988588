//Common CSS used in different components, please do not change. Used globally

//Tags hard reset rules.

body{
  overflow-y: overlay;
}

h2 {
  font-size: 1.25rem; //20px
}

p {
  font-size: 0.875rem; //14px
}

a {
  text-decoration: none;
  color: black;

  &:visited {
    color: inherit;
  }
}

p, h1, h2, h3, h4, h5 {
  margin: 0;
}



//Tags hard reset rules END

//flex-rules
.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-sBetween {
  display: flex;
  justify-content: space-between;
}

.flex-sEvenly {
  display: flex;
  justify-content: space-between;
}

//flex-rules END

//colors
.progressColor {
  color: $green;
}

.regressColor {
  color: $red;
}

.stagColor {
  color: $yellow;
}

.blackColor {
  color: $black;
}

.grayColor {
  color: $gray3;
}

.infoPurple {
  color: $infoPurple;
}

.infoRed {
  color: $infoRed;
}

.infoYellow {
  color: $infoYellow;
}

.infoGreen {
  color: $infoGreen;
}

.infoBlue {
  color: $infoBlue;
}

//colors END

//scrollBar

::-webkit-scrollbar {
  width: 1vh;

  &-track {
    display: none;
  }

  &-thumb {
    background: $blue1;
    border-radius: 16px;

    &:hover {
      background: $blue0;
    }
  }
}

//scrollBar END


//Misc rules
.noIndent {
  padding: 0;
  margin: 0;
}

.fancyShadow {
  box-shadow:0 4px 8px rgba(0,0,0,0.24);
}

.fancyShadowOnlyBottom {
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 1px;
    z-index: -1;
    box-shadow: 0 0 8px 2px #000000;
    transition: box-shadow 2s ease;
  }
}

.width100 {
  width: 100%;
}

//.infoBlock {
//  width: 14%;
//}

.icon {
  padding-bottom: .5vh;
}

.bold {
  font-weight: 600;
}

//Misc rules END

//Division of pages (Wrappers)
.mainWrapper {
  width: 70%;
}

.sideBarWrapper {
  width: 25%;
  margin-left: auto;
}

.modalAlert {
  background: $white;
  margin: 7vh auto 0;
  width: 30vw;
  border-radius: 16px;
  padding: 2vh 3vw;
  outline: none;
}

.modalWrapper {
  @include itemWrapperStyles;
  overflow: hidden;
  width: 1024px;
  margin: 7vh auto 0;
  height: 85vh;
  outline: none;
}

.modalWrapper__compare{
  @include itemWrapperStyles;
  overflow: hidden;
  width: 100%;
  margin: 7vh auto 0;
  height: 85vh;
  outline: none;
}

//Used in front of gamesList
.numberWrapper {
  width: 3vw;
}

//Division of pages END
