.gameRanking {
  flex-direction: column;
  overflow: hidden;
  width: inherit;

  &__itemWrapper {
    overflow-y: overlay;
    overflow-x: hidden;

    :hover {
      background: $gray4;
    }


    &__item {
      padding: 2vh 0;
      border-bottom: solid 1px $gray4;
    }
  }
}