.multi{
  padding-bottom: 3vh;
  &__gameName{
    padding: 2vh 2vw;
  }
  &__difficulty{
    padding: 0.5vh 4vw;
  }
  &__parameterWrapper{
    margin: 1vh 3vw;
  }
}