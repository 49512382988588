.pieWrapper{
  @include itemWrapperStyles;
  height: 40vh;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: 2vh;

  &__dropdown{
    margin: 0 auto;
  }

  &__totalPlayed{
    margin: 0 auto;
    font-weight: 600;
  }
}