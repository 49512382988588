.gameRankingCompare{
  @include itemWrapperStyles;
  flex-direction: column;

  &__dropdown{
    padding: 3vh 2vw;
  }

  &__itemWrapper{
    flex-direction: column;

    :last-child{
      border:0;
    }

    &__item{
      border-bottom: solid 2px $gray4;
    }

  }
}