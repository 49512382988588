.gameRankingItem {
  width: 100%;

  &__imgWrapper{

    img{
      border-radius: 8px;
      width: 96px;
      height: 96px;
    }
  }

  &__infoWrapper {
    width: 100%;
    padding: 1vh 1vw;

    &__thirdLine{
      padding-top: 2vh;
    }
  }
}