.compareWrapper{
  flex-direction: column;

  .deviceAndPlaylistName{
    min-width: 30vw;
    background: $white;
    padding: 1.5vh 3vw;
    align-items: center;
  }
}

.compare {
  flex-direction: column;
  margin-top: 3vh;
  padding-bottom: 3vh;


  //.compare + .compare construction
  &#{&} {
    margin-left: 2vw;
  }

  //First Element (Donut and scores)
  &__first {
    @include itemWrapperStyles;
    justify-content: center;
    padding: 2vh;

    &__donutWrapper {
      align-self: center;
    }

    &__scores {

      &__mini {
        flex-direction: column;
      }
    }
  }


  //Second Element (Heatmap)
  &__heatmap{
    @include itemWrapperStyles;
    margin-top: 3vh;
    padding: 3vh 2vw;
    margin-bottom: 3vh;
  }

  //Third element (Game Rankings Compare)
  &__third{
    margin-top: 3vh;
  }

}