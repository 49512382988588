.score {

  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 1vh 2vw;

  &__name {
    color: $gray3;
  }

  &__value {
    border: 0 !important;
    padding: 3vh 0;
    font-size: 2.5rem;
  }

}