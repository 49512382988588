.loader{
  flex-direction: column;

  h2{
    font-weight: 300;
    font-size: 2.5rem;
    padding-bottom: 3vh;
  }
  h3{
    padding-bottom: 2vh;
  }
}